import config from "./config";

export const getBaseUrl = () => {
	// const host = window.location.host; // tenant10.lacolmena.app
	const hostname = window.location.origin; // https://tenant10.lacolmena.app
	// const arrDomain = host.split(".");

	const arrProtocol = hostname.split(":"); // [https]

	// let subdomain = arrDomain[0];
	let protocol = arrProtocol[0];
	// const url = `${protocol}://${subdomain}.${config.SERVICE_URL}`; // https://tenant10.lacolmena.app/api-tenant/
	const url = `${protocol}://${config.SERVICE_URL}`; // https://tenant10.lacolmena.app/api-tenant/

	return url;
};

export const getIndex = (idx: number, offset: number, limit: number) => {
	const currentPage = Math.floor(offset / limit) + 1;

	return idx + (currentPage - 1) * (limit || 10) + 1;
};

export const setLongNewDate = (date: Date) => {
	const newDate = new Intl.DateTimeFormat("default", {
		dateStyle: "long",
		timeStyle: "long",
		hour12: false,
		timeZone: "America/Lima",
	}).format(new Date(date));

	return newDate;
};

export const setShortNewDate = (date: Date) => {
	const newDate = new Intl.DateTimeFormat("default", {
		dateStyle: "medium",
		timeStyle: "medium",
		hour12: false,
		timeZone: "America/Lima",
	}).format(new Date(date));

	return newDate;
};

export const setNormalNewDate = (date: Date) => {
	const newDate = new Intl.DateTimeFormat("default", {
		dateStyle: "short",
		timeZone: "America/Lima",
	}).format(new Date(date));

	return newDate;
};

export const setNormalNewTime = (date: Date) => {
	const newDate = new Intl.DateTimeFormat("default", {
		timeStyle: "medium",
		hour12: false,
		timeZone: "America/Lima",
	}).format(new Date(date));

	return newDate;
};

export const normalizedQuery = (texto: string): string => {
	return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const getTimeDifference = (value: Date): string => {
	const currentDate: number = Date.now();
	const newCreatedAt: Date = new Date(value);

	// Calcula la diferencia en milisegundos
	const timeDifference: number = currentDate - newCreatedAt.getTime();

	// Calcula la diferencia en minutos
	const minutesDifference: number = Math.floor(timeDifference / (1000 * 60));

	if (minutesDifference < 60) {
		// Si han pasado menos de 60 minutos, devolver la diferencia en minutos
		if (minutesDifference === 1) {
			return `00:${minutesDifference} min`;
		} else {
			return `00:${minutesDifference} mins`;
		}
	} else {
		// Calcula la diferencia en horas y minutos
		const hoursDifference: number = Math.floor(minutesDifference / 60);
		const remainingMinutes: number = minutesDifference % 60;

		if (hoursDifference < 24) {
			// Si han pasado menos de 24 horas, devolver la diferencia en horas y minutos
			if (hoursDifference === 1) {
				return `${hoursDifference}:${remainingMinutes} h`;
			} else {
				return `${hoursDifference}:${remainingMinutes} h`;
			}
		} else {
			// Calcula la diferencia en días, semanas, meses y años
			const daysDifference: number = Math.floor(hoursDifference / 24);
			const weeksDifference: number = Math.floor(daysDifference / 7);
			const monthsDifference: number = Math.floor(daysDifference / 30);
			const yearsDifference: number = Math.floor(monthsDifference / 12);

			if (daysDifference < 7) {
				return `${daysDifference} días`;
			} else if (weeksDifference < 4) {
				return `${weeksDifference} semanas`;
			} else if (monthsDifference < 12) {
				return `${monthsDifference} meses`;
			} else {
				return `${yearsDifference} años`;
			}
		}
	}
};

export const padNumberWithZeros = (num: number, length: number = 11): string => {
	const numStr = num.toString();
	if (numStr.length >= length) {
		return numStr;
	}
	return numStr.padStart(length, "0");
};

export const getHours = (e: Date | undefined) => {
	if (e) {
		const seconds = e?.getSeconds() > 9 ? e.getSeconds() : `0${e.getSeconds()}`;
		const minutes = e?.getMinutes() > 9 ? e.getMinutes() : `0${e.getMinutes()}`;
		let hours = e?.getHours() > 9 ? e.getHours() : `0${e.getHours()}`;
		return `${hours}:${minutes}:${seconds}`;
	}
	return "";
};
