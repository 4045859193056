import { useUserContext } from "@context/UserContext";
import { FC, Fragment } from "react";
import { Navigate } from "react-router";

type AuthenticatedGuardProps = {
	fallback: string;
	children: React.ReactNode;
};

const AuthenticatedGuard: FC<AuthenticatedGuardProps> = ({ children, fallback }) => {
	const userContext = useUserContext();

	if (!userContext) {
		return <Navigate to={fallback} />;
	}

	return <Fragment>{children}</Fragment>;
};

export default AuthenticatedGuard;
