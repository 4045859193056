import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
	Card,
	Stack,
	Theme,
	CardContent,
	Typography,
	useMediaQuery,
	Button,
	Paper,
	TextField,
	CardActions,
} from "@mui/material";

import { useDialogState } from "@hooks/common";
import CustomDialog from "@components/dialog/CustomDialog";
import { useSignIn, useValidateAuthWith2FA } from "@hooks/mutations/auth";
import ImageSVG from "@components/logo/ImageSGV";
import assets from "@assets/index";
import LoginForm from "@components/auth/LoginForm";

type AuthProps = {};

const Auth: FC<AuthProps> = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const snackbar = useSnackbar();
	const state = location.state as Partial<{ path: string }>;

	const [text, setText] = useState("");
	const [response, setResponse] = useState<any>(null);
	const [rememberMe, setRememberMe] = useState(false);
	const [code, setCode] = useState("");
	const inputRef = useRef<HTMLInputElement | null>(null);

	const showDialog = useDialogState();

	const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.only("lg"));

	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between("sm", "md"));

	const [blockingTime, setBlokingTime] = useState<number | null>(null); // Estado para almacenar el número de minutos

	const signIn = useSignIn({
		onSuccess: (response) => {
			if (response.isTfaEnabled && !response.success) {
				showDialog.toggle();
				setResponse(response);
				setText(response.message);
			} else {
				navigate(state?.path || "/dashboard");
			}
		},
		onError: (err) => {
			const match = err.message.match(/vuelva en (\d+) minutos/);
			if (match && match[1]) {
				const minutos = parseInt(match[1], 10);
				setBlokingTime(minutos);
			}

			snackbar.enqueueSnackbar(err.message, {
				variant: "error",
				preventDuplicate: true,
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "left",
				},
			});
		},
	});

	/** Validamos el codigo (2FA) ingresado */
	const validAuthWith2FA = useValidateAuthWith2FA({
		onSuccess: (response) => {
			if (response.success) {
				navigate(state?.path || "/dashboard");
			} else {
				setText(response.message);
			}
		},
		onError: (err) => {
			snackbar.enqueueSnackbar(err.message, {
				variant: "error",
				preventDuplicate: true,
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "left",
				},
			});
		},
	});

	/** Solo se activa la elevación cuando es tablet */
	let cardElevation = 3;
	if (isTablet) {
		cardElevation = 8;
	} else if (isDesktop) {
		cardElevation = 7;
	}
	const cancelProcess = () => {
		setCode("");
		showDialog.toggle();
	};

	useEffect(() => {
		// Focus the input field when the dialog opens
		if (showDialog.open) {
			setTimeout(() => {
				inputRef.current?.focus();
			}, 0);
		}
	}, [showDialog.open, code.length]);

	const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter" && code.length === 6) {
			// Ejecutar la acción solo si el código tiene una longitud de 6 caracteres
			validAuthWith2FA.mutate({
				tfaCode: code,
				session: response.session,
			});
		}
	};

	return (
		<Paper>
			<CustomDialog open={showDialog.open} onClose={cancelProcess} title="Autenticación" showTittle={false}>
				<Stack alignItems="center">{text}</Stack>
				<Stack direction="column" justifyContent="center" sx={{ marginTop: 2 }} spacing={1}>
					<Stack direction="column" spacing={1}>
						<TextField
							name="code"
							onChange={(e) => {
								const value = e.target.value;
								if (/^[0-9]+$/.test(value) || value === "") {
									setCode(value);
								}
							}}
							value={code}
							inputRef={inputRef}
							onKeyDown={handleEnterKeyPress}
							inputProps={{
								maxLength: 6,
								style: {
									fontSize: "50px", // Tamaño del texto
									textAlign: "center", // Alineación horizontal
									display: "flex",
									justifyContent: "center", // Alineación vertical
									alignItems: "center",
									height: "100%",
									letterSpacing: "20px",
									fontWeight: "bold", // Hace que el texto sea negrito
								},
							}}
						/>
					</Stack>
					<Stack direction="row" spacing={1} alignContent="center" alignItems="center">
						<Button
							variant="contained"
							color="success"
							disabled={code.length < 6}
							onClick={() => {
								validAuthWith2FA.mutate({
									tfaCode: code,
									session: response.session,
								});
							}}
							sx={{ flex: 1 }}
						>
							Validar
						</Button>
						<Button onClick={cancelProcess} variant="outlined" color="error" sx={{ flex: 1 }}>
							Cancelar
						</Button>
					</Stack>
				</Stack>
			</CustomDialog>
			<Stack
				sx={{
					flexBasis: "100%",
					minHeight: "100vh",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
				}}
				spacing={2}
			>
				<Stack direction="column" alignItems="center" spacing={2}>
					<Stack alignItems="center" spacing={1}>
						<Stack direction="row" alignItems="center">
							<Stack direction="row" alignItems="center">
								<Typography variant="h4" fontWeight={800} style={{ textShadow: "0.5px 1px 2px rgba(0, 0, 0, 0.25)" }}>
									Restaurantify
								</Typography>
								<Typography variant="h3">.</Typography>
								<ImageSVG image={assets.images.logoRestaurantifyOlla} width="60px" height="100%" />
							</Stack>
						</Stack>
						<Typography variant="subtitle1">Iniciar sesión</Typography>
					</Stack>
				</Stack>

				<Card elevation={cardElevation} sx={{ zIndex: "0", borderRadius: 3 }}>
					<Stack
						component={CardContent}
						maxWidth="500px"
						width={{ xs: "auto", md: "350px" }}
						spacing={2}
						sx={{
							padding: (t) => t.spacing(3, 4),
							marginTop: (t) => `${t.spacing(5)}`,
						}}
					>
						<LoginForm
							onSubmit={signIn.mutate}
							submitting={signIn.isLoading}
							setRememberMe={setRememberMe}
							blockingTime={blockingTime ?? 0}
						/>
					</Stack>
					<Stack
						component={CardActions}
						sx={{
							marginBottom: (t) => `${t.spacing(2)}`,
						}}
					>
						<Typography
							variant="caption"
							align="center"
							sx={{
								display: "inline-block",
								textDecoration: "none",
							}}
							component={Link}
							to="/auth/forgot/password"
							color="#666666"
						>
							¿Olvidó su contraseña?
						</Typography>
					</Stack>
				</Card>
				<Typography align="center" variant="caption" component="span">
					&copy; 2022 Haya Group Technology All Rights Reserved.
				</Typography>
			</Stack>
		</Paper>
	);
};

export default Auth;
