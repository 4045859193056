import { useUserContext } from "@context/UserContext";
import { FC, Fragment } from "react";
import { Navigate } from "react-router";

type LoginGuardProps = {
	fallback: string;
	children: React.ReactNode;
};
const LoginGuard: FC<LoginGuardProps> = ({ children, fallback }) => {
	const userContext = useUserContext();

	if (userContext) {
		return <Navigate to={fallback} />;
	}

	return <Fragment>{children}</Fragment>;
};

export default LoginGuard;
