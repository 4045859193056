import { Fragment, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAppState } from "@redux/features/appStateSlice";

type PageWrapperProps = {
	state?: string;
	children: ReactNode;
};

const PageWrapper = (props: PageWrapperProps) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.state) {
			dispatch(setAppState(props.state));
		}
	}, [dispatch, props]);

	return <Fragment>{props.children}</Fragment>;
};

export default PageWrapper;
