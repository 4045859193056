import axios, { Axios, AxiosError } from "axios";
import {
	ForgotPayload,
	ForgotResponse,
	LoginFormPayload,
	LoginResponse,
	RefreshTokenResponse,
	ResetPasswordPayload,
	ResetPasswordResponse,
	Status2FACodePayload,
	Status2FACodeResponse,
} from "@ctypes/auth";
import { APIError } from "@ctypes/common";

export const loginAPI = async (data: LoginFormPayload) => {
	try {
		const response = await axios.request<LoginResponse>({
			method: "POST",
			url: "/authentication/signin",
			data,
		});

		return response.data;
	} catch (error: any) {
		if ((error as AxiosError<APIError>).response?.data) {
			throw (error as AxiosError<APIError>).response?.data;
		}
		if (error.message === "Network Error") throw new Error("Lo sentimos no podemos conectarnos al servidor...");
		else {
			throw new Error(error);
		}
	}
};

export const refreshTokenAPI = async (token: string, axiosInstance: Axios) => {
	try {
		const response = await axiosInstance.request<RefreshTokenResponse>({
			method: "GET",
			url: "/authentication/refresh",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const validAuthWith2FACode = async (payload: Status2FACodePayload) => {
	try {
		const response = await axios.request<Status2FACodeResponse>({
			method: "POST",
			url: "/authentication/tfa/validcode",
			data: payload,
			withCredentials: true, // Añade esta línea para incluir las cookies en la solicitud
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const logoutAPI = async () => {
	try {
		await axios.request({
			method: "POST",
			url: "/authentication/signout",
		});
	} catch (error) {}
};

export const forgotPassword = async ({ email }: ForgotPayload) => {
	try {
		const response = await axios.request<ForgotResponse>({
			method: "POST",
			url: `/authentication/forgot/password`,
			data: {
				email,
			},
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const resetPassword = async ({ newPassword, newConfirmPassword, token }: ResetPasswordPayload) => {
	try {
		const response = await axios.request<ResetPasswordResponse>({
			method: "POST",
			url: `/authentication/reset/password/${token}`,
			data: {
				newPassword,
				newConfirmPassword,
			},
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};
