import { useEffect, useRef, useState } from "react";

export const useDialogState = () => {
	const [open, setOpen] = useState(false);
	const toggle = useRef(() => {
		setOpen((o) => !o);
	}).current;

	return { open, toggle };
};

class Filters<T> {
	constructor(
		private setFilters: React.Dispatch<React.SetStateAction<T>>,
		private filters: T,
		private setFiltersTemp: React.Dispatch<React.SetStateAction<T>>,
		private filtersTemp: T,
	) {}
	changeLazy(params: Partial<T>) {
		this.setFilters((prev) => ({ ...prev, ...params }));
	}
	change(params: Partial<T>) {
		this.setFiltersTemp((prev) => ({ ...prev, ...params }));
	}
	get listLazy() {
		return this.filters;
	}
	get list() {
		return this.filtersTemp;
	}
}
const useFilters = <T>(payload: T) => {
	const [filters, setFilters] = useState<T>(payload);
	const [filtersTemp, setFiltersTemp] = useState<T>(payload);
	return new Filters(setFilters, filters, setFiltersTemp, filtersTemp);
};

export const useLazySearch = <T = {}>(payload: T) => {
	const filters = useFilters<T>(payload);
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			filters.changeLazy(filters.list);
		}, 1000);

		return () => clearTimeout(timeoutId);
	}, [filters.list]);
	return filters;
};

const useCustomFilters = <T>(filters: T) => {
	const [fs, setFs] = useState<T>(filters);
	class Filter {
		set(params: Partial<T>) {
			setFs((prev) => ({ ...prev, ...params }));
		}
		change(params: Partial<T>) {
			setFs((prev) => ({ ...prev, ...params }));
		}
		get() {
			return fs;
		}
		get list() {
			return fs;
		}
	}
	const filter = new Filter();
	return { filter };
};

/** Para la busqueda del cliente en la venta */
export const useCustomLazySearch = <T = {}>(payload: T) => {
	const { filter } = useCustomFilters<T>(payload);
	const [filters, setFilters] = useState<T>(payload);
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			filter.change(filters);
		}, 500);

		return () => clearTimeout(timeoutId);
	}, [filters]);
	return { filter, setFilters, filters };
};
