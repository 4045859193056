import { ThemeOptions } from "@mui/material";

const lightTheme: ThemeOptions = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	palette: {
		mode: "light",
		background: {
			paper: "var(--white)",
			default: "#faf8fb",
		},
	},
	typography: {
		fontFamily: "Roboto",
		fontSize: 12,
	},
};

export default lightTheme;
