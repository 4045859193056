import { FC } from "react";
import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Stack, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
type CloseReason = "backdropClick" | "escapeKeyDown" | "closeButtonClick";

type CustomDialogProps = Omit<DialogProps, "disableEscapeKeyDown"> & {
	id?: string;
	title?: string;
	showTittle?: boolean;
	actions?: JSX.Element;
	onClose: (reason: CloseReason) => void;
	children?: React.ReactNode;
};

// const DraggableDialog = ({ id, ...props }: { id: string } & PaperProps) => {
// 	return (
// 		<Draggable handle={`#${id}`} cancel={'[class*="MuiDialogContent-root"]'}>
// 			<Paper {...props} />
// 		</Draggable>
// 	);
// };

const CustomDialog: FC<CustomDialogProps> = ({ id, title, children, onClose, actions, showTittle, ...props }) => {
	const handleClose: DialogProps["onClose"] = (_event, reason) => {
		if (reason !== "backdropClick") {
			onClose && onClose(_event, reason);
		}
	};

	return (
		<Dialog
			{...props}
			disableEscapeKeyDown
			onClose={handleClose}
			// PaperComponent={(dialogProps) => <DraggableDialog id={id || "DefaultDialog"} {...dialogProps} />}
		>
			{showTittle && (
				<DialogTitle
					id={`${id}`}
					style={{ cursor: "move" }}
					sx={{
						justifyContent: "space-between",
						alignItems: "center",
						paddingTop: 0.25,
						paddingBottom: 0.25,
						background: "#584475",
						color: "#fff",
					}}
				>
					<Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
						<Typography>{title}</Typography>
						<IconButton onClick={() => onClose("closeButtonClick")}>
							<CloseIcon sx={{ color: "#fff" }} />
						</IconButton>
					</Stack>
				</DialogTitle>
			)}
			<DialogContent sx={{ "&.MuiDialogContent-root": { paddingY: 3 } }}>{children}</DialogContent>
		</Dialog>
	);
};

export default CustomDialog;
