export const LS_IDX_MENU_TAB = "LS_IDX_MENU_TAB";
export const LS_CUSTOMER = "LS_CUSTOMER";

export enum Role {
	OWNER = "Owner",
	ADMIN = "admin",
	MANAGER = "manager",
	CASHIER = "cashier",
	PRODUCTION = "production",
	MOZO = "mozo",
	DELIVERY = "delivery",
}
