import { CSSProperties, FC } from "react";
import assets from "@assets/index";

type ImageSVGProps = {
	width?: string;
	height?: string;
	image?: string;
	disabled?: boolean;
	style?: CSSProperties; // Agrega la propiedad 'style'
};

const ImageSVG: FC<ImageSVGProps> = ({
	image = assets.images.noImage,
	width = "18px",
	height = "18px",
	disabled = false,
	style = {},
}) => {
	const imageStyle: React.CSSProperties = {
		width: `${width}`,
		height: `${height}`,
		filter: disabled ? "grayscale(100%)" : "none", // Aplicar desenfoque si está deshabilitado
		// cursor: disabled ? "not-allowed" : "pointer", // Cambiar el cursor si está deshabilitado

		...style,
	};

	return <img src={image} style={imageStyle} alt="icon" />;
};

export default ImageSVG;
