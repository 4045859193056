import { createContext, FC, ReactNode, useContext, useMemo, useRef, useState } from "react";
import { ThemeProvider, ThemeOptions } from "@mui/material";
import getTheme, { CustomPaletteMode } from "@theme/index";

type ThemeContextReturn = Partial<{
	mode: CustomPaletteMode;
	toggle(m: CustomPaletteMode, argsMui?: ThemeOptions): void;
}>;

const ThemeContext = createContext<ThemeContextReturn>({});

type ThemeContextProviderProps = {
	children: ReactNode;
};
export const ThemeContextProvider: FC<ThemeContextProviderProps> = ({ children }) => {
	const [mode, setMode] = useState<CustomPaletteMode>("defaultTheme");
	const [args, setArgs] = useState<ThemeOptions>({});

	const toggle = useRef((mode: CustomPaletteMode, argsMui: ThemeOptions = {}) => {
		setMode(mode);
		setArgs(argsMui);
	}).current;

	const theme = useMemo(() => getTheme(mode, args), [mode, args]);

	return (
		<ThemeContext.Provider value={{ mode, toggle }}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export const useThemeContext = (): ThemeContextReturn => {
	return useContext(ThemeContext);
};
