import { LoginResponse, RefreshTokenResponse, User } from "@ctypes/auth";
import axios from "axios";
import { createContext, FC, useContext, useEffect, useRef, useState } from "react";

type CustomLoginResponse = Omit<LoginResponse, "success" | "pending" | "message">;
type UserContextReturn = CustomLoginResponse;
type UserProfileUpdate = Omit<
	User,
	| "accessToken"
	| "refreshToken"
	| "session"
	| "isTfaEnabled"
	| "email"
	| "_id"
	| "isDeleted"
	| "createdAt"
	| "updatedAt"
>;

type UserContextHandlersReturn = {
	updateUser: (payload: CustomLoginResponse, rememberMe?: boolean) => void;
	updateTokens: (payload: RefreshTokenResponse) => void;
	updateUserProfile: (profileUpdate: UserProfileUpdate) => void;
	clearUser: () => void;
	editUser: (user: Partial<User>) => void;
};

const UserContext = createContext<UserContextReturn | null>(null);
const UserContextHandlers = createContext<UserContextHandlersReturn>({} as UserContextHandlersReturn);

export const UserContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
	const [user, setUser] = useState<CustomLoginResponse | null>(null);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		const storedUser = localStorage.getItem("userInfo");

		if (storedUser) {
			const parsedUser: LoginResponse = JSON.parse(storedUser);
			setUser(parsedUser);
			axios.defaults.headers.common = {
				Authorization: `Bearer ${parsedUser.accessToken}`,
			};
		}
		setReady(true);
	}, []);

	const updateUser = useRef<UserContextHandlersReturn["updateUser"]>((payload, remeberMe?) => {
		setUser(payload);
		if (remeberMe) {
			localStorage.setItem("userInfo", JSON.stringify(payload));
		}

		axios.defaults.headers.common = {
			Authorization: `Bearer ${payload.accessToken}`,
		};
	}).current;

	const updateTokens = useRef<UserContextHandlersReturn["updateTokens"]>((payload) => {
		axios.defaults.headers.common.Authorization = `Bearer ${payload.accessToken}`;

		setUser((u) => {
			const newUser = { ...u!, ...payload };
			localStorage.setItem("userInfo", JSON.stringify(newUser));

			return newUser;
		});
	}).current;

	const clearUser = useRef<UserContextHandlersReturn["clearUser"]>(() => {
		setUser(null);
		localStorage.removeItem("userInfo");
	}).current;

	const editUser = (u: Partial<User>) => {
		if (user?.user) {
			const updatedUser = { ...user, user: { ...user.user, ...u } };
			setUser(updatedUser);
			localStorage.setItem("userInfo", JSON.stringify(updatedUser));
		}
	};

	const updateUserProfile = useRef<UserContextHandlersReturn["updateUserProfile"]>((profileUpdate) => {
		if (!user) return; // Si no hay usuario, no hay nada que actualizar

		const updatedUser = { ...user, user: { ...user.user, ...profileUpdate } };
		setUser(updatedUser);
		localStorage.setItem("userInfo", JSON.stringify(updatedUser));
	}).current;

	return (
		<UserContextHandlers.Provider value={{ updateUser, updateTokens, clearUser, editUser, updateUserProfile }}>
			<UserContext.Provider value={user}>{ready && children}</UserContext.Provider>
		</UserContextHandlers.Provider>
	);
};

export const useUserContext = () => {
	return useContext(UserContext);
};

export const useUserContextHandlers = () => {
	return useContext(UserContextHandlers);
};
