import { createContext, useContext, useEffect, useState } from "react";
import { useCompanyAdvancedSetting } from "@hooks/queries/company";
import { CompanyAdvancedSetting } from "@ctypes/company";

export interface IContextConfig {
	setConfig: React.Dispatch<React.SetStateAction<CompanyAdvancedSetting | null>> | null;
	Config: CompanyAdvancedSetting | null;
}

// ConfigContext
export const ConfigContext = createContext<IContextConfig>({
	setConfig: null,
	Config: null,
});

interface IContextProps {
	children: React.ReactNode;
}
export const GlobalConfigContextProvider: React.FC<IContextProps> = ({ children }) => {
	const [Config, setConfig] = useState<CompanyAdvancedSetting | null>(null);

	const [loaded, setLoaded] = useState(false);
	const { data } = useCompanyAdvancedSetting();

	useEffect(() => {
		if (!data?.documents) return;
		const payload = { ...data?.documents[0] };
		if (!payload.typeGlobalDscto) payload.typeGlobalDscto = "number";
		setConfig(payload);
		setLoaded(true);
	}, [data?.documents]);

	useEffect(() => {
		if (loaded) return;
		const configuration = localStorage.getItem("Config");
		if (!configuration) return;
		const partial = JSON.parse(configuration) as CompanyAdvancedSetting;
		setConfig(partial);
	}, [loaded]);

	useEffect(() => {
		if (!Config) return;
		localStorage.setItem("Config", JSON.stringify(Config));
	}, [Config]);

	return <ConfigContext.Provider value={{ Config, setConfig }}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => {
	return useContext(ConfigContext);
};
