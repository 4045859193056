import { DataProductCart } from "@ctypes/common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Función para recuperar los datos del carrito de localStorage
const getInitialOrderState = () => {
	const orderData = localStorage.getItem("orderData");
	return orderData
		? JSON.parse(orderData)
		: {
				orderId: undefined,
				numOrder: undefined,
				table: null,
				products: [],
		  };
};

interface OrderList {
	invoiceId?: string;
	customerId?: string;
	paymentType?: string;
	subTotalAmountToPayment?: number;
	totalTaxes?: number;
	totalCommision?: number;
	totalDiscounts?: number;
	totalAmountToPayment?: number;
	totalCashToPayment?: number;
	totalChangeBack?: number;
	totalPayment?: number;
	orderId?: string;
	numOrder?: string;
	table?: any;
	products?: DataProductCart[]; // Agregamos la lista de productos al estado
}

const initialState: OrderList = getInitialOrderState(); // Inicializar el estado con los datos recuperados de localStorage

const ordersStateSlice = createSlice({
	name: "ordersState",
	initialState,
	reducers: {
		setOrderTable(state, action: PayloadAction<OrderList>) {
			state.orderId = action.payload.orderId;
			state.numOrder = action.payload.numOrder;
			state.table = action.payload.table;
			state.products = action.payload.products;

			// Guardar en localStorage
			localStorage.setItem("orderData", JSON.stringify(state));
		},
		clearOrderTable(state) {
			state.orderId = undefined;
			state.numOrder = undefined;
			state.table = null;
			state.products = [];

			localStorage.removeItem("orderData"); // Eliminamos los datos del localStorage cuando se limpia la orden
		},
		// Esta acción se utiliza para agregar un producto a la lista de productos de la orden
		addProductCart(state, action: PayloadAction<DataProductCart>) {
			const newProduct = action.payload;

			// Si el producto no existe, agrégalo a la lista de productos
			if (state.products) {
				state.products.push(newProduct);
			}

			localStorage.setItem("orderData", JSON.stringify(state));
		},
		updateQtyProductCart(state, action: PayloadAction<{ productId: string; qty: number }>) {
			const { productId, qty } = action.payload;
			const productToUpdate = state.products?.find((product) => product.item === productId);

			if (productToUpdate) {
				productToUpdate.qty = qty;
				productToUpdate.subTotal = productToUpdate.qty * (productToUpdate.price || 0);

				// Actualizar solo el producto modificado en el localStorage
				localStorage.setItem(
					"orderData",
					JSON.stringify({
						...state,
						products: state.products,
					}),
				);
			}
		},
		addNotesProductCart(state, action: PayloadAction<{ productId: string; notes: string }>) {
			const { productId, notes } = action.payload;

			const productToUpdate = state.products?.find((product) => product.item === productId);

			if (productToUpdate) {
				productToUpdate.notes = notes;
				localStorage.setItem(
					"orderData",
					JSON.stringify({
						...state,
						products: state.products,
					}),
				);
			}
		},
		removeProductCart(state, action: PayloadAction<{ productId: string }>) {
			const { productId } = action.payload;
			state.products = state.products?.filter((product) => product.item !== productId);

			localStorage.setItem("orderData", JSON.stringify(state));
		},
		updateSuppliesList(state, action: PayloadAction<{ productId: string; suppliesList: any[] }>) {
			const { productId, suppliesList } = action.payload;

			const productToUpdate = state.products?.find((product) => product.item === productId);
			if (productToUpdate) {
				productToUpdate.suppliesList = suppliesList;
				localStorage.setItem(
					"orderData",
					JSON.stringify({
						...state,
						products: state.products,
					}),
				);
			}
		},
		addExtraSupply(state, action: PayloadAction<{ productId: string; newExtraSupply: any }>) {
			const { productId, newExtraSupply } = action.payload;

			// Buscar el producto en el estado por productId
			const productToUpdate = state.products?.find((product) => product.item === productId);
			if (productToUpdate) {
				// Verificar si el extra supply ya existe en suppliesList
				const existingExtraSupplyIndex = productToUpdate.suppliesList.findIndex(
					(supply) => supply.supply._id === newExtraSupply.supply._id,
				);

				if (existingExtraSupplyIndex !== -1) {
					// Si ya existe, actualizar la cantidad y subtotal si es necesario
					const existingExtraSupply = productToUpdate.suppliesList[existingExtraSupplyIndex];

					// Calcular la nueva cantidad sumando la nueva cantidad
					const newQuantity = existingExtraSupply.qty + newExtraSupply.qty;

					// Actualizar el precio del producto si existe
					if (productToUpdate.price) {
						productToUpdate.price += (newExtraSupply.supply.saleUnitPrice || 0) * newExtraSupply.qty;
					}

					// Actualizar el subtotal del producto
					const extraSupplyPrice = newExtraSupply.supply.saleUnitPrice || 0;
					productToUpdate.subTotal += extraSupplyPrice * newExtraSupply.qty;

					// Actualizar la cantidad en el producto
					productToUpdate.suppliesList[existingExtraSupplyIndex] = {
						...existingExtraSupply,
						qty: newQuantity,
					};
				} else {
					// Si no existe, agregar el nuevo extra supply a suppliesList
					productToUpdate.suppliesList.push(newExtraSupply);

					// Sumar el saleUnitPrice del extra supply al precio del producto si existe
					if (productToUpdate.price) {
						productToUpdate.price += (newExtraSupply.supply.saleUnitPrice || 0) * newExtraSupply.qty;
					}

					// Calcular el nuevo subtotal del producto sumando el precio del suministro extra al subtotal actual
					productToUpdate.subTotal += (newExtraSupply.supply.saleUnitPrice || 0) * newExtraSupply.qty;
				}

				// Actualizar el estado en localStorage
				localStorage.setItem(
					"orderData",
					JSON.stringify({
						...state,
						products: state.products,
					}),
				);
			}
		},
		removeExtraSupply(state, action: PayloadAction<{ productId: string; supplyId: string }>) {
			const { productId, supplyId } = action.payload;

			const productToUpdate = state.products?.find((product) => product.item === productId);
			if (productToUpdate) {
				// Encontrar el suministro extra que se va a eliminar
				const supplyToRemove = productToUpdate.suppliesList?.find((supply) => supply.supply._id === supplyId);
				if (supplyToRemove) {
					// Calcular el precio y subtotal que se debe restar
					const supplyPrice = supplyToRemove.supply.saleUnitPrice || 0;
					const supplyQty = supplyToRemove.qty || 0;
					const supplyTotalPrice = supplyPrice * supplyQty;

					// Restar el precio del suministro extra del precio del producto
					if (productToUpdate.price) {
						productToUpdate.price -= supplyTotalPrice;
					}

					// Restar el subtotal
					productToUpdate.subTotal -= supplyTotalPrice;
				}

				// Eliminar el suministro extra de la lista
				productToUpdate.suppliesList = productToUpdate.suppliesList?.filter((supply) => supply.supply._id !== supplyId);

				// Actualizar el estado en localStorage
				localStorage.setItem(
					"orderData",
					JSON.stringify({
						...state,
						products: state.products,
					}),
				);
			}
		},
	},
});

export const {
	setOrderTable,
	clearOrderTable,
	addProductCart,
	updateQtyProductCart,
	addNotesProductCart,
	removeProductCart,
	updateSuppliesList,
	removeExtraSupply,
	addExtraSupply,
} = ordersStateSlice.actions;

export default ordersStateSlice.reducer;
