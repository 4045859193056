import { ThemeOptions } from "@mui/material";

const defaultTheme: ThemeOptions = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	palette: {
		mode: "light",
		background: {
			paper: "#fff",
			default: "#faf8fb",
		},
		common: {
			black: "#505050",
			white: "#fff",
		},
		primary: {
			main: "#886ab5",
			contrastText: "#fff",
		},
		secondary: {
			main: "#868e96",
			contrastText: "#fff",
		},
		error: {
			main: "#fd3995",
			contrastText: "#fff",
		},
		warning: {
			main: "#ffc241",
			contrastText: "#212529",
		},
		info: {
			main: "#2196F3",
			contrastText: "#fff",
		},
		success: {
			main: "#1dc9b7",
			contrastText: "#fff",
		},
		text: {
			primary: "#666",
			secondary: "#000666",
		},
	},
	typography: {
		fontFamily: "Roboto, Helvetica Neue, Helvetica, Arial",
		fontSize: 10.5,
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					color: "#886ab5",
					background: "#fff",
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					background: "rgb(242, 242, 242)",
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					color: "#fff",
					background: "#584475",
					backgroundImage: "linear-gradient(270deg,rgba(51,148,225,0.18),transparent)",
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					color: "#bdafd1", // Color de fondo cuando no está activo
					"&: hover": {
						backgroundColor: "rgba(255,255,255,.04)",
						color: "#fff",
					},
					"&:hover .MuiListItemIcon-root": {
						color: "#fff", // Color del ícono al realizar hover
					},
					"&.Mui-selected": {
						color: "#fff", // Color de fondo cuando está activo
					},
					"&.Mui-selected .MuiListItemIcon-root": {
						color: "#fff", // Color del ícono cuando está seleccionado
					},
					"&.MuiListItemIcon": {
						color: "#000",
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: { color: "#bdafd1" },
			},
		},
	},
};

export default defaultTheme;
