import { Route } from "react-router-dom";
import { ReactNode } from "react";
import { RouteType } from "./Config";
import appRoutes from "./AppRoutes";
import PageWrapper from "@components/layout/PageWrapper";

const generateRoute = (routes: RouteType[]): ReactNode => {
	return routes.map((route, index) =>
		route.index ? (
			<Route
				index
				path={route.path}
				key={index}
				element={<PageWrapper state={route.state}>{route.element}</PageWrapper>}
			/>
		) : (
			<Route
				path={route.path}
				element={<PageWrapper state={route.child ? undefined : route.state}>{route.element}</PageWrapper>}
				key={index}
			>
				{route.child && generateRoute(route.child)}
			</Route>
		),
	);
};

export const routes: ReactNode = generateRoute(appRoutes);
