import { createTheme, ThemeOptions } from "@mui/material";
import lightTheme from "./light";
import defaultTheme from "./default";
import darkTheme from "./dark";

export const themes = {
	light: lightTheme,
	dark: darkTheme,
	defaultTheme: defaultTheme,
};

export type CustomPaletteMode = keyof typeof themes;

const getTheme = (mode: CustomPaletteMode, args: ThemeOptions = {}) => createTheme(themes[mode], args);

export default getTheme;
