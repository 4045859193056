import { getCompanyAPI, getCompanyAdvancedSettingAPI } from "@api/company";
import { APIError } from "@ctypes/common";
import {
	CompanyAdvancedSettingFiltersPayload,
	CompanyAdvancedSettingResponse,
	CompanyFilters,
	CompanyFiltersPayload,
	CompanyResponse,
} from "@ctypes/company";
import { useQuery, UseQueryOptions } from "react-query";

export const useCompany = (params?: CompanyFiltersPayload, options?: UseQueryOptions<CompanyResponse, APIError>) => {
	return useQuery<CompanyResponse, APIError>(
		["companies", params],
		() => {
			// let payload: CategoriesFilters | undefined = undefined;
			let payload: CompanyFilters | undefined;

			if (params) {
				const { filterBy, offset, query, limit } = params;
				payload = {
					offset: offset,
					limit,
				} as CompanyFilters;

				if (query) {
					payload[filterBy] = query;
				}
			}
			return getCompanyAPI(payload);
		},
		options,
	);
};

export const useCompanyAdvancedSetting = (
	params?: CompanyAdvancedSettingFiltersPayload,
	options?: UseQueryOptions<CompanyAdvancedSettingResponse, APIError>,
) => {
	return useQuery<CompanyAdvancedSettingResponse, APIError>(
		["companiesAdvancedSetting", params],
		() => {
			return getCompanyAdvancedSettingAPI();
		},
		options,
	);
};
