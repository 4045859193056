import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SuppliesList } from "@ctypes/common";

const initialState: SuppliesList[] = [];

export const suppliesStateSlice = createSlice({
	name: "suppliesState",
	initialState,
	reducers: {
		addSupply: (state, action: PayloadAction<SuppliesList>) => {
			const supplyToAddOrUpdate = action.payload;
			const existingSupplyIndex = state.findIndex((data) => data.supply._id === supplyToAddOrUpdate.supply._id);

			if (existingSupplyIndex !== -1) {
				// Si el suministro ya existe, reemplázalo
				state[existingSupplyIndex] = supplyToAddOrUpdate;
			} else {
				// Si el suministro no existe, agrégalo a la lista
				state.push(supplyToAddOrUpdate);
			}
		},
		setSupplyOptional: (state, action: PayloadAction<string>) => {
			const supplyId = action.payload;
			const existingSupply = state.find((data) => data.supply._id === supplyId);

			if (existingSupply) {
				existingSupply.isOptional = !existingSupply.isOptional;
				existingSupply.isRequired = !existingSupply.isOptional;
			}
		},
		removeSupply: (state, action: PayloadAction<string>) => {
			return state.filter((data) => data.supply._id !== action.payload);
		},
		clearSupplies: (state) => {
			state.splice(0, state.length);
		},
	},
});

export const { addSupply, removeSupply, setSupplyOptional, clearSupplies } = suppliesStateSlice.actions;

export default suppliesStateSlice.reducer;
