import { FC, Suspense, lazy, useEffect } from "react";
import "./assets/css/loader.css";
import "./index.css";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { routes } from "./routes";
import { Box } from "@mui/material";
import assets from "./assets";
import { UserContextProvider } from "@context/UserContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { getBaseUrl } from "@tools/common";
import axios from "axios";
import Auth from "@pages/auth/Auth";
import LoginGuard from "@guards/LoginGuard";
import AuthenticatedGuard from "@guards/AuthenticatedGuard";
import { RESET_PASSWORD_ROUTE } from "@routes/ResponseTypes";
import { GlobalConfigContextProvider } from "@context/ConfigContext";
import { ReactQueryDevtools } from "react-query/devtools";

axios.defaults.baseURL = getBaseUrl();

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 0,
		},
	},
});

const Loader: FC<{ relative?: boolean }> = ({ relative }) => {
	return (
		<Box
			height={`100${relative ? "%" : "vh"}`}
			width={`100${relative ? "%" : "vw"}`}
			display="flex"
			justifyContent="center"
			alignContent="center"
		>
			<img src={assets.images.loaderCircle} alt="loader" width={100} height={100} style={{ margin: "auto" }} />
		</Box>
	);
};

const MainLayout = lazy(() => import("@pages/dashboard/MainLayout"));
const ForgotPassword = lazy(() => import("@pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("@pages/auth/ResetPassword"));
const SolidSecurity = lazy(() => import("@components/security/SolidSecurity"));
const Order = lazy(() => import("@pages/pos/Order"));
const NotFoundPage = lazy(() => import("@pages/NotFoundPage"));

function App() {
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			// Mostrar el diálogo de confirmación personalizado antes de salir de la página
			event.returnValue = "¿Estás seguro de que deseas salir de la página?";
		};

		// Agregar el event listener cuando el componente se monta
		window.addEventListener("beforeunload", handleBeforeUnload);

		// Eliminar el event listener cuando el componente se desmonta
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []); // Dependencias vacías para que solo se ejecute una vez

	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools />
			<UserContextProvider>
				<GlobalConfigContextProvider>
					<SnackbarProvider
						anchorOrigin={{
							horizontal: "center",
							vertical: "top",
						}}
					>
						<BrowserRouter>
							<SolidSecurity />
							<Suspense fallback={<Loader />}>
								<Routes>
									<Route path="auth/forgot/password" element={<ForgotPassword />} />
									<Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />
									<Route path="/auth" element={<Auth />} />
									<Route
										path="/"
										element={
											<LoginGuard fallback="/dashboard">
												<Auth />
											</LoginGuard>
										}
									/>
									<Route
										path="/"
										element={
											<AuthenticatedGuard fallback="/auth">
												<MainLayout>
													<Suspense fallback={<Loader relative />}>
														<Outlet />
													</Suspense>
												</MainLayout>
											</AuthenticatedGuard>
										}
									>
										{routes}
									</Route>
									<Route
										path="/pointOfSale/Order"
										element={
											<AuthenticatedGuard fallback="/auth">
												<Suspense fallback={<Loader relative />}>
													<Order />
												</Suspense>
											</AuthenticatedGuard>
										}
									/>
									<Route path="/*" element={<NotFoundPage />} />
								</Routes>
							</Suspense>
						</BrowserRouter>
					</SnackbarProvider>
				</GlobalConfigContextProvider>
			</UserContextProvider>
		</QueryClientProvider>
	);
}

export default App;
