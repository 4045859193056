import { ThemeOptions } from "@mui/material";

const darkTheme: ThemeOptions = {
	palette: {
		mode: "dark",
		background: {
			paper: "#212225",
			default: "#37393e",
		},
		common: {
			black: "#fff",
		},
		primary: {
			main: "#886ab5",
			contrastText: "#fff",
		},
		secondary: {
			main: "#868e96",
			contrastText: "#fff",
		},
		error: {
			main: "#ff5b57",
			contrastText: "#fff",
		},
		warning: {
			main: "#ffc241",
			contrastText: "#212529",
		},
		info: {
			main: "#2196f3",
			contrastText: "#fff",
		},
		success: {
			main: "#1dc9b7",
			contrastText: "#fff",
		},
		text: {
			primary: "#fff",
			secondary: "#888",
			disabled: "rgba(255, 255, 255, 0.5)",
		},
		divider: "rgba(255,255,255,.25)",
		action: {
			active: "#888",
			hover: "rgba(0,0,0,.1)",
			hoverOpacity: 0.08,
		},
	},
	typography: {
		fontFamily: "Roboto",
		fontSize: 12,
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					color: "#fff",
					background: "linear-gradient(90deg, rgba(88,68,117,1) 0%, rgba(81,82,136,1) 100%)",
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					background: "linear-gradient(90deg, rgba(88,68,117,1) 0%, rgba(81,82,136,1) 100%)",
				},
			},
		},
	},
};

export default darkTheme;
