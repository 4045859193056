import axios, { AxiosError } from "axios";
import { APIError } from "@ctypes/common";
import {
	Company,
	CompanyAdvancedSetting,
	CompanyAdvancedSettingFilters,
	CompanyAdvancedSettingResponse,
	CompanyFilters,
	CompanyPayload,
	CompanyResponse,
	EditCompanyAdvancedSettingPayload,
	EditCompanyPayload,
} from "@ctypes/company";

/** FUNCION PARA TRAER TODOS LOS REGISTROS DEL BACKEND */
export const getCompanyAPI = async (params?: CompanyFilters) => {
	try {
		const response = await axios.request<CompanyResponse>({
			url: "/company/all",
			method: "GET",
			params,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

/** FUNCION PARA TRAER TODOS LOS REGISTROS DEL BACKEND */
export const getCompanyAdvancedSettingAPI = async (params?: CompanyAdvancedSettingFilters) => {
	try {
		const response = await axios.request<CompanyAdvancedSettingResponse>({
			url: "/company/config/all",
			method: "GET",
			params,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

/** FUNCIONES CREATE, EDIT Y DELETE */
export const createCompanyAPI = async (payload: CompanyPayload) => {
	const formData = new FormData();

	if (payload.favicon) formData.append("favicon", String(payload.favicon));

	formData.append("number", String(payload.number));
	formData.append("name", payload.name);
	formData.append("tradeName", String(payload.tradeName));
	formData.append("soapShippingSend", String(payload.soapShippingSend));
	formData.append("soapTypes", String(payload.soapTypes));
	formData.append("soapUserName", String(payload.soapUserName));
	formData.append("soapPassword", String(payload.soapPassword));
	formData.append("soapExternalUrl", String(payload.soapExternalUrl));
	formData.append("certificate", String(payload.certificate));
	formData.append("certificateDateOfDue", String(payload.certificateDateOfDue));
	formData.append("integratedQueryClientId", String(payload.integratedQueryClientId));
	formData.append("integratedQueryClientSecret", String(payload.integratedQueryClientSecret));
	formData.append("passwordCDT", String(payload.passwordCDT));

	try {
		const response = await axios.request<Company>({
			url: "/companies/create",
			method: "POST",
			data: formData,
		});

		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const editCompanyAPI = async ({ _id, ...payload }: EditCompanyPayload) => {
	try {
		const formData = new FormData();

		formData.append("name", String(payload.name));
		formData.append("tradeName", String(payload.tradeName));
		formData.append("soapShippingSend", String(payload.soapShippingSend));
		formData.append("soapTypes", String(payload.soapTypes));
		formData.append("soapUserName", String(payload.soapUserName));
		formData.append("soapPassword", String(payload.soapPassword));
		formData.append("soapExternalUrl", String(payload.soapExternalUrl));
		formData.append("certificateDateOfDue", String(payload.certificateDateOfDue));
		formData.append("integratedQueryClientId", String(payload.integratedQueryClientId));
		formData.append("integratedQueryClientSecret", String(payload.integratedQueryClientSecret));
		formData.append("passwordCDT", String(payload.passwordCDT));

		formData.append("number", String(payload.number));

		formData.append("deleteFile", String(payload.deleteFile));
		if (payload.certificate) formData.append("certificate", payload.certificate);

		formData.append("favicon", String(payload.favicon));

		const response = await axios.request<Company>({
			url: `/company/update/${_id}`,
			method: "PATCH",
			data: formData,
		});
		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const deleteCertificateAPI = async (id: string) => {
	try {
		const response = await axios.request<Company>({
			url: `/company/delete/cert/${id}`,
			method: "PUT",
		});
		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};

export const editCompanyAdvancedSettingAPI = async ({ _id, ...payload }: EditCompanyAdvancedSettingPayload) => {
	try {
		const response = await axios.request<CompanyAdvancedSetting>({
			url: `/company/config/update/${_id}`,
			method: "PATCH",
			data: payload,
		});
		return response.data;
	} catch (error) {
		throw (error as AxiosError<APIError>).response?.data;
	}
};
